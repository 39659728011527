<template>
  <nav class="bg-gray-800">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0 text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-file-earmark-bar-graph"
              viewBox="0 0 16 16"
            >
              <path
                d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z"
              />
              <path
                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"
              />
            </svg>
          </div>
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

              <router-link
                to="/"
                class="hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="dashboardMenuActive"
                >Dashboard</router-link
              >

              <router-link
                to="/products"
                class="hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="productsMenuActive"
                v-if="isAdminInvoiceAccess"
                >Products</router-link
              >

              <router-link
                to="/customers"
                class="hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="customersMenuActive"
                v-if="isAdminManagerAccess"
                >Customers</router-link
              >

              <router-link
                to="/users"
                class="hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="usersMenuActive"
                v-if="isAdminManagerAccess"
                >Users</router-link
              >

              <router-link
                to="/transactions"
                class="hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="transactionsMenuActive"
                v-if="isAdminInvoiceAccess"
                >Create Transactions</router-link
              >

              <router-link
                to="/transactions/history"
                class="hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="transactionsHistoryMenuActive"
                v-if="isAdminAdministrationAccess"
                >Transactions History</router-link
              >

              <router-link
                to="/report-per-product"
                class="hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="reportPerProductMenuActive"
                v-if="isAdminManagerAccess"
                >Report per Product</router-link
              >
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <button
              @click="logoutClick"
              class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Logout
            </button>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button
            @click="isMobileMenuOpen = !isMobileMenuOpen"
            type="button"
            class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg
              v-if="!isMobileMenuOpen"
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg
              v-if="isMobileMenuOpen"
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div
      class="md:hidden"
      id="mobile-menu"
      :class="{ hidden: !isMobileMenuOpen }"
    >
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link
          to="/"
          class="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          :class="dashboardMenuActive"
          >Dashboard</router-link
        >

        <router-link
          to="/products"
          class="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-sm font-medium"
          :class="productsMenuActive"
          v-if="isAdminManagerAccess"
          >Products</router-link
        >

        <router-link
          to="/customers"
          class="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-sm font-medium"
          :class="customersMenuActive"
          v-if="isAdminManagerAccess"
          >Customers</router-link
        >

        <router-link
          to="/users"
          class="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          :class="usersMenuActive"
          v-if="isAdminManagerAccess"
          >Users</router-link
        >

        <router-link
          to="/transactions"
          class="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          :class="transactionsMenuActive"
          v-if="isAdminInvoiceAccess"
          >Create Transactions</router-link
        >

        <router-link
          to="/transactions/history"
          class="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          :class="transactionsHistoryMenuActive"
          v-if="isAdminAdministrationAccess"
          >Transactions History</router-link
        >

        <router-link
          to="/report-per-product"
          class="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
          :class="reportPerProductMenuActive"
          v-if="isAdminManagerAccess"
          >Report per Product</router-link
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="mt-3 px-2 space-y-1">
          <button
            @click="logoutClick"
            class="text-gray-300 hover:bg-gray-700 hover:text-white block w-full px-3 py-2 rounded-md text-sm text-left font-medium"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Cookie from "js-cookie";

export default {
  name: "Navbar",
  data() {
    return {
      isMobileMenuOpen: false,
      isAdminManagerAccess:
        Cookie.get("adpos_user_role") == "owner" ||
        Cookie.get("adpos_user_role") == "admin_manager",
      isAdminInvoiceAccess:
        Cookie.get("adpos_user_role") == "owner" ||
        Cookie.get("adpos_user_role") == "admin_manager" ||
        Cookie.get("adpos_user_role") == "admin_invoice",
      isAdminAdministrationAccess:
        Cookie.get("adpos_user_role") == "owner" ||
        Cookie.get("adpos_user_role") == "admin_manager" ||
        Cookie.get("adpos_user_role") == "admin_invoice" ||
        Cookie.get("adpos_user_role") == "admin_administration",
    };
  },
  methods: {
    logoutClick() {
      this.$store.dispatch("auth/logout");
    },
  },
  mounted() {
    //console.log(this.$route.name);
  },
  computed: {
    dashboardMenuActive() {
      switch (this.$route.name) {
        case "Dashboard":
          return "bg-gray-900";
        default:
          return "";
      }
    },
    productsMenuActive() {
      switch (this.$route.name) {
        case "Products":
          return "bg-gray-900";
        case "CreateProduct":
          return "bg-gray-900";
        case "EditProduct":
          return "bg-gray-900";
        default:
          return "";
      }
    },
    customersMenuActive() {
      switch (this.$route.name) {
        case "Customers":
          return "bg-gray-900";
        case "CreateCustomer":
          return "bg-gray-900";
        case "EditCustomer":
          return "bg-gray-900";
        default:
          return "";
      }
    },
    usersMenuActive() {
      switch (this.$route.name) {
        case "Users":
          return "bg-gray-900";
        case "CreateUser":
          return "bg-gray-900";
        case "EditUser":
          return "bg-gray-900";
        default:
          return "";
      }
    },
    transactionsMenuActive() {
      switch (this.$route.name) {
        case "Transactions":
          return "bg-gray-900";
        default:
          return "";
      }
    },
    transactionsHistoryMenuActive() {
      switch (this.$route.name) {
        case "TransactionsHistory":
          return "bg-gray-900";
        case "TransactionsDetail":
          return "bg-gray-900";
        default:
          return "";
      }
    },
    reportsMenuActive() {
      switch (this.$route.name) {
        case "Reports":
          return "bg-gray-900";
        default:
          return "";
      }
    },
    reportPerProductMenuActive() {
      switch (this.$route.name) {
        case "ReportPerProduct":
          return "bg-gray-900";
        case "ReportPerProductDetail":
          return "bg-gray-900";
        default:
          return "";
      }
    },
  },
};
</script>
